html {
  background-color: rgba(78, 78, 78, 0.15);
  scroll-behavior: smooth;
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  padding: 50px;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.315);
  background-color: rgba(255, 255, 255, 0.301);
  margin: 60px;
  margin-left: 2%;
  margin-right: 2%;
}

@media (max-width: 800px) {
  .main-container {
    flex-direction: column-reverse;
    gap: 30px;
    padding: 30px;
  }
}

.main-container2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  margin: 60px;
  margin-right: 2%;
  margin-left: 2%;
  padding: 50px;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.315);
  background-color: rgba(255, 255, 255, 0.301);
}

@media (max-width: 800px) {
  .main-container2 {
    flex-direction: column;
    gap: 30px;
    padding: 30px;
  }
}

.image {
  width: 750px;
  height: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 1200px) {
  .image {
    width: 400px;
    height: 325px;
  }
}

@media (max-width: 800px) {
  .image {
    width: 100%;
    height: 300px;
  }
}

.text-container {
  flex: 1;
  font-size: calc(0.7vw + 0.7vh + 0.2vmin);
  line-height: 2.5;
  letter-spacing: 1px;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  text-align: left;
}

@media (max-width: 800px) {
  .text-container {
    font-size: calc(1vw + 1vh + 0.2vmin);
  }
}
@media (min-width: 1600px) {
  .text-container {
    line-height: 4;
  }
}

.header {
  text-align: center;
  font-size: 4em; /* This will increase the font size relative to its PARENT */
  font-weight: bold; /* This will make the text bold */
  color: #333; /* This will change the text color */
  text-transform: uppercase; /* This will make the text uppercase */
  letter-spacing: 4px; /* This will further increase the spacing between letters */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* This will add a shadow effect to the text */
  font-family: "Arial", sans-serif; /* This will change the font family */
}

.header::first-letter {
  color: #f00;
}

.header2 {
  text-align: center;
  font-size: 4em; /* This will increase the font size relative to its PARENT */
  font-weight: bold; /* This will make the text bold */
  color: #333; /* This will change the text color to white */
  text-transform: uppercase; /* This will make the text uppercase */
  letter-spacing: 4px; /* This will further increase the spacing between letters */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* This will add a shadow effect to the text */
  font-family: "Arial", sans-serif; /* This will change the font family */
}

.header2::first-letter {
  color: #3f51b5;
}

.welcome {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 30%;
  justify-content: flex-start;
  align-items: center;
  font-size: 10em;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("/public/agHome.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.5s ease-in-out;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  letter-spacing: 5px;
  color: rgba(0, 0, 0, 0.801);
  opacity: 1;
  text-align: center;
  box-shadow: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.welcome p {
  font-size: 0.18em;
}

/* Media queries will help us override at certain dimensions*/
@media (min-width: 1200px) {
  .welcome {
    padding-top: 10%;
  }
}

@media (max-width: 800px) {
  .welcome {
    font-size: 5em;
  }
  .welcome p {
    font-size: 0.18em;
  }
}

.welcome.scrolled {
  opacity: 0;
}

.arrow-down {
  position: absolute;
  margin-top: 800px; /* Center the arrow */
}

.arrow-down .MuiSvgIcon-root {
  color: #3333339a;
  animation: bounce 2s infinite;
  font-size: 0.5em;
}

@media (max-width: 800px) {
  .arrow-down {
    margin-top: 450px; /* Adjust as needed */
  }
}

/* Keyframes are used to create animations */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
