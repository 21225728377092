.appbar-title,
.appbar-title-big {
  font-size: 38px;
}

.appbar-title .appbar-title-small {
  font-size: 14px;
}

/* Media query for small screens */
@media (max-width: 800px) {
  .appbar-title,
  .appbar-title-big {
    font-size: 20px;
  }
  .appbar-title .appbar-title-small {
    font-size: 8px;
  }
}

/* Media query for very small screens */
@media (max-width: 400px) {
  .appbar-title,
  .appbar-title-big {
    font-size: 14px;
  }
  .appbar-title .appbar-title-small {
    font-size: 6px;
  }
}
