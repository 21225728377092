.text {
  font-size: 22px;
  letter-spacing: 1.3px;
}

@media (max-width: 600px) {
  .text {
    font-size: 14px;
  }
}
